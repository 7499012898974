.footer {
  font-family: "var(--primaryFont)", sans-serif;
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.footer p {
  font-weight: 500;
  font-family: var(--primaryFont);
  font-size: 18px;
}

.footer p span {
  font-size: 24px;
}

.footer a {
  text-decoration: none;
}
